
@media only screen and (min-width: 768px){
    .after-carousel-div{
        display: none;
    }
}
@media screen and (min-width:768px) and (max-width:1702px) {
    .after-carousel-div{
        display: flex;
    }
}

.securityImg{
    padding: 20px;
}
.slider{
    position: sticky;
    top: auto;
}