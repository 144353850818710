#maindivContact h1{
    font-size: xx-large;
    vertical-align: middle;
    text-align: center;
    
}
.container{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;	
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.container div{
    background-color: rgb(239, 242, 242);
    height: 550px;
    width: 400px;
    margin-bottom: 10%;
    padding-top: 30px;
}
.container .div1{
    margin-left: 100px;/* 
    background-image: url("../../images/background2.jpg"); */
}
.div1 h5{
    font-size: larger;
    text-align: center;
    margin-bottom: 30px;
}
.container .div2{
    /* background-image: url("../../images/Security\ Guard.jpg"); */
}
.container .div1 ul{
    font-size: large;
    padding-inline-start: 50px;
    list-style-type: circle;
    
}
.container .div2 p{
    margin-bottom: 30px;

}
.container P{
    justify-content: center;
    padding-inline-start: 20PX;
    padding-inline-end: 20px;
    font-size: large;
    margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    /* .container div{
        height: 300px;
        margin-bottom: 10%;
    } */
  }