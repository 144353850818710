body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*lpgo css*/
.logo{
  height: 40px;
  width: 80px;
  border-radius: 12%;
}
/**/
/*header css*/
.main{
  height: 68px;
}
.main h1 span{
  color: hsl(330, 88%, 13%);
}

#mainimg{
  padding-bottom: 22px;
  width:auto;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  /*background-image: url("images/security.jpeg");*/
}

 .name{
  font-family: 'Montserrat';
  color: #f5f3f3;
  width: 500px;
  position:absolute;
    left: 50%;
    text-align: center;
  top: 500px;
  transform: translate(-50%,-50%);
  }
  .name span{
    color: #1f18f5;
    border-bottom: 2px solid #0a0a0a;
  }
  .name .details{
    color: hsla(0, 3%, 6%, 0.8);
    font-size: 30px;
  }
  .s-box .details{
    font-size: 20px;
    margin-left: 10px;
  }
  .name h1{
  font-family:'Montserrat';
  font-size: 46px;
  margin:0px;
  letter-spacing: 3px;
  color:#ffffff;
  }

/* footer css */
.footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(244, 242, 243);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.contactIconsOnFooter{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.contactIconsOnFooter span{
  color: #000;
  padding: 10px;
}
.whatsAppF{
  color: rgb(20, 85, 34);
  text-shadow: 1px 1px 1px rgb(5, 71, 38);
  font-size: 30px;
}
.phoneF{
  color: rgb(10, 10, 10);
  text-shadow: 1px 1px 1px rgb(5, 71, 38);
  font-size: 30px;
}
.envelopeF{
  color: rgb(68, 7, 233);
  text-shadow: 1px 1px 1px rgb(5, 71, 38);
  font-size: 30px;
}
/*end of footer css*/

@media(max-width:1190px){
  #main{
    background-size: 100% !important;
    width: auto;
  }
  .name{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .header-btns{
    margin-left: 10em;
  }
  
  .features-model img{
    height:400px;
    }
    #services{
    height:auto;
    }
    #about{
      height: auto;
    }
    #contact{
      height: auto;
    }
    .b-container{
    flex-wrap: wrap;
  }
    .s-box{
    flex-grow: 1;
  }
  .s-box1{
    flex-grow: 1;
  }
  .s-b-img img{
    object-fit:cover;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  
  }
  
  @media(max-width:970px){
    #main{
      background-size: 970px !important;
      margin-left: -115px;
      width: auto;
    }
    #services{
      height: auto;
    }
  .features-model{
    display: none;
  }
  .s-heading h1{
    font-size: 2.5rem;
    color: #9b047a;
  }
  .features-text h2{
    font-weight: 600;
  }
  .b-container{
    margin-top: 30px;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  .contactIconsOnFooter{
    margin-top: 0px;
    display:flex;
    justify-content:center;
    align-items: center;
    color: #fff;
    text-align: center;
    flex-direction:column;
  }
  .whatsAppF{
    color: rgb(20, 85, 34);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .phoneF{
    color: rgb(250, 241, 247);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .envelopeF{
    color: rgb(68, 7, 233);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .logodiv {
    display: block;
    padding-bottom: 0%;
  }
  }

  /*Very smaill screens*/
  @media screen and (min-width:100px) and (max-width:300px) {
    #about{
      height: auto;
    }
    .logo{
      width: 20px;
    }
  }
  
  /*Samsung galaxy*/
  @media screen and (min-width:100px) and (max-width:780px) {
    
    #main{
      background-size: 100% !important;
      width: auto;
      height: 60px;
    }
    #main h1{
      color: #9b047a;
      
    }
    #main h1 span{
      font-size: 30px;
      
    }
  .name{
    width:60%;
    top: 570px;
    }
    #mainimg .name h1 span{
      color: #3f04ac;
      font-family: 'Times New Roman', Times, serif;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: normal;
    }
    .active,
    .services,
    .about,
    .contact{
      text-align: center;
      
      width: 100%;
      transition: all 0.5s ease;
    }
    .logoH {
      width: 140px;
      height: 160px;
    }
    .details-contact-us{
      font-size:medium;
    }
    #mainimg{
      background-size: 100% !important;
      width: auto;
      height: 100vh;
    }
    #mainimg .name{
      top: 500px;
    }
}

@media screen and (min-width:768px) and (max-width:1702px){
  #mainimg .name{
    top: 450px;
  }
  #mainimg .name .details{
    font-size: 40px;
  }
}

/* ipad Mini Landscape */
@media screen and (min-width:768px) and (max-width:1702px) {
  #main{
    background-size:100% !important;
  }
  .logodiv {
    display: none;
    padding-bottom: 0%;
  }
  #main h1{
    color: #9b047a;
    
  }
  #main h1 span{
    font-size: 30px;
    
  }
  .name .details{
    color: #eeeaea;
  }
.name{
  width:60%;
  top: 580px;
  }
  .nav-cont .active{
    color: #fff;
      text-align: center;
      
      width: 100%;
      transition: all 0.5s ease;
  }
  .nav-cont .about{
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }
    .nav-cont .services{
      color: #fff;
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }
    .nav-cont .contact{
      color: #fff;
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }

}
    .scroll{
        left: 42.5%;
    }
        @media(max-width:400px){
            .s-box{
                width: 100%;
                height:450px;
            }
            .s-box3{
              width: 100%;
              height:450px;
          }
          .s-box .s-b-text{
            height: fit-content;
          }
        }

@media only screen and (-webkit-min-device-pixel-ratio: 2.5) {
          /* CSS for Microsoft surface Duo*/
          #main{
            background-size:100% !important;
          }
        }

        @media only screen and (max-width: 800px) {
          .contactIconsOnFooter{
            height: 500px;
          }
        }
